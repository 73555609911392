.portrait-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-center {
  text-align: center;
}
.portrait-container > img {
  height: 215px;
}
img.img-portrait-style-refinement {
  height: 150px;
}
