.header-container {
  margin-bottom: 10px;
  background-color: #4C1DB7;
  color: #fff;
  width: 100vw;
  padding: 10px;
  box-sizing: border-box;
  max-height: 100px;
}
.header-title {
  margin: 0;
  font-size: 0.8em;
  color: #fff;
  font-weight: 300;
}
.center {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
}