.border-button-container {
    color: #fff;
    background-color: #4C1DB7;
    font-size: 14px;
    border-radius: 4px;
    padding: 20px 15px;
    cursor: pointer;
    width: 100%;
    font-family: 'Roboto';
    font-weight: 500;
    letter-spacing: 1.25px;
}