.section-container {
  margin-left: 20px;
  margin-right: 20px;
}
.photoslide-container {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.photoslide-container .carrousel-photos {
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  padding: 22px;
}
.photoslide-container .carrousel-status-photos {
  display: flex;
  align-items: center;
  padding: 22px;
}
.photo-carrousel {
  margin: 0px 10px;
  width: 50px;
  height: 50px;
}
.photo-carrousel-like{
  width: 20px;
}

.unclicked {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.damage_clicked {
  display: flex;
  width: 50%;
  border-radius: 26px;
  align-items: center;
  background-color: #E2F5EA;
  margin-bottom: 10px;
}
.scratch_clicked {
  display: flex;
  align-items: center;
  background-color: #FCEBDB;
  margin-bottom: 10px;
  width: 50%;
  border-radius: 26px;
}
.hit_clicked,
.other_clicked {
  display: flex;
  align-items: center;
  background: #EB5757;
  margin-bottom: 10px;
  width: 50%;
  border-radius: 26px;
}

.unclicked > span,
.damage_clicked > span, 
.scratch_clicked > span, 
.hit_clicked > span,
.other_clicked > span {
  margin-left: 10px;
}
.titles {
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #4B4B4B;
}
.status-container {
  margin-left: 10px;
  padding-right: 20px;
}

.add-photo-container {
  height: 50px;
  width: 50px;
  border: 1px dotted;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}