.inspection-container{
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.btn-submit {
  border: 0px;
  padding: 15px 20px;
  margin: 10px 8px;
  background: #6716F5;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: #FFFFFF;
  text-transform: uppercase;
}
.stepper-container {
  display: flex;
  padding: 0px 25px;
}
.steps-count {
  color: #979797;
}
/* remover luego */
.MuiMobileStepper-progress {
  width: 100% !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #AF8AFC !important;
} 
.MuiLinearProgress-colorPrimary{
  background-color: #E4DAF9 !important;
}
.input-group > * {
  display: block;
}
.container-button {
  display: flex;
  justify-content: center;
}
.btn-purple {
  background-color: #6716F5;
  color: #fff;
  width: 70%;
}
.btn-purple.disabled {
  background-color: #E0E0E0;
}
.container-label {
  display: flex;
  align-items: center;
}
.container-label > label {
  color: #AF8AFC;
}
.error-message {
  color: red;
}
.root-class {
  padding: 0px;
}

.input-group .container-label label {
  margin-bottom: 5px;
  letter-spacing: 1.25px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  color: #AF8AFC;
  text-transform: uppercase;
}
