.introduction-container{
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.introduction-container-size{
  min-height: 80vh;
}

.tit-color-1 {
  color: #141B6A;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.text-content-styles {
  font-weight: 300;
  color: #333;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: 300;
}