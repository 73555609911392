.react-html5-camera-photo>img, 
.react-html5-camera-photo>video {
  height: 80vh;
}

@media only screen and (orientation: landscape) {
  .react-html5-camera-photo>img, 
  .react-html5-camera-photo>video {
    height: 80vh;
  }
  #container-circles {
    bottom: 30% !important;
  }
}