.text-center {
  text-align: center;
}
.container-success {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 25px
}

.container-success > * {
  margin-bottom: 5vh;
}